import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { InputBase, Box, Button as MuiButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useAppState } from 'appContext'
import { Loader, Tooltip, useNotification } from 'packages/eid-ui'
import {
    useApplicationCredentialsHiddenProps,
    useCheckIfMasterPasswordExist,
} from 'hooks'
import { useIsSmallScreen } from 'packages/core'
import { TextFormatter } from 'components'

const useStyles = makeStyles((theme) => ({
    root: ({ isDetailed }) => ({
        background: '#262fa1',
        marginTop: isDetailed ? '-32px' : '-20px',
        width: 'fit-content',
        position: 'absolute',
        zIndex: 999,
        borderRadius: '5px',
        marginLeft: '-20px',
        '&:before': {
            content: "''",
            position: 'absolute',
            left: '60px',
            bottom: '-5px',
            width: '10px',
            height: '10px',
            transform: 'rotate(45deg)',
            backgroundColor: '#262fa1',
        },
    }),
    input: ({ isDetailed }) => ({
        padding: isDetailed ? '0px' : '16px',
        color: 'black !important',
        display: 'grid !important',
    }),
}))

const DummyPasswordBox = ({ data, isDetailed = false }) => {
    const classes = useStyles({ isDetailed })
    const { t } = useTranslation()
    const [{ masterPassword }] = useAppState()
    const [fetchedValue, setFetchedValue] = useState()
    const [fetchHiddenProps, setFetchHiddenProps] = useState(false)
    const [isRevealed, setIsRevealed] = useState(false)
    const [isCopied, setIsCopied] = useState(false)
    const [showTooltip, setShowTooltip] = useState(false)
    const [openPopover, setOpenPopover] = useState(false)
    const isSmallScreen = useIsSmallScreen()
    const { masterPasswordExists } = useCheckIfMasterPasswordExist()
    const { showWarningMessage } = useNotification()

    const { data: hiddenProps, isLoading } =
        useApplicationCredentialsHiddenProps(
            data.item.id,
            masterPassword,
            fetchHiddenProps,
        )

    const showPopover = () => {
        setOpenPopover(true)
    }

    const hidePopover = () => {
        setOpenPopover(false)
    }

    useEffect(() => {
        if (hiddenProps) {
            let apiResponseValue = hiddenProps?.password
            setFetchedValue(apiResponseValue)

            if (isCopied) {
                navigator.clipboard.writeText(apiResponseValue)
                hidePopover()
                alert(t('CopiedMessage'))
            }
        }
    }, [hiddenProps])

    if (!data.item.canCheckIn) {
        return (
            <Tooltip
                placement="top-start"
                open={showTooltip}
                onOpen={() => setShowTooltip(true)}
                onClose={() => setShowTooltip(false)}
                title={t('CheckoutToRevealPasswordMessage')}
                interactive
                arrow={false}
                tooltipStyles={{ marginBottom: '-10px' }}
            >
                <InputBase
                    value={'*'.repeat(10)}
                    onClick={() => setShowTooltip(!showTooltip)}
                    classes={{ input: classes.input }}
                    disabled
                />
            </Tooltip>
        )
    }

    const handleCopy = () => {
        if (!masterPasswordExists()) {
            showWarningMessage(t('MasterPasswordDoesNotExist'))
            return
        }
        setIsCopied(true)
        if (fetchedValue) {
            navigator.clipboard.writeText(fetchedValue)
            hidePopover()
            alert(t('CopiedMessage'))
        } else {
            setFetchHiddenProps(true)
        }
    }

    const toogleReveal = () => {
        setTimeout(() => {
            setIsRevealed(!isRevealed)
        }, [200])
        hidePopover()
        if (!fetchedValue) {
            setFetchHiddenProps(true)
        }
    }

    if (data.item.canCheckIn) {
        if (isSmallScreen) {
            return (
                <InputBase
                    value={
                        hiddenProps
                            ? hiddenProps.password
                                ? hiddenProps.password
                                : '-'
                            : '*'.repeat(10)
                    }
                    classes={{ input: classes.input }}
                    disabled
                />
            )
        } else {
            return (
                <Box
                    style={{ cursor: 'pointer', overflowWrap: 'anywhere' }}
                    onMouseEnter={showPopover}
                    onMouseLeave={hidePopover}
                >
                    <div
                        className={classes.root}
                        style={{
                            display: openPopover ? 'flex' : 'none',
                        }}
                        onMouseLeave={hidePopover}
                    >
                        <MuiButton
                            style={{
                                color: 'white',
                                textTransform: 'none',
                            }}
                            size="small"
                            variant="text"
                            onClick={handleCopy}
                        >
                            {t('Copy')}
                        </MuiButton>
                        <MuiButton
                            style={{
                                color: 'white',
                                borderRadius: '0px',
                                borderLeft: '1px solid white',
                                textTransform: 'none',
                            }}
                            size="small"
                            variant="text"
                            onClick={toogleReveal}
                        >
                            {isRevealed ? t('Hide') : t('Reveal')}
                        </MuiButton>
                    </div>
                    {isLoading ? (
                        <Loader height={25} size={0.7} />
                    ) : (
                        <TextFormatter
                            style={{
                                fontSize: '13px',
                                padding: isDetailed ? '0px' : '16px',
                            }}
                            value={
                                isRevealed
                                    ? fetchedValue
                                        ? fetchedValue
                                        : '-'
                                    : '*'.repeat(10)
                            }
                            maxCharacters="100"
                        />
                    )}
                </Box>
            )
        }
    }
}
export default DummyPasswordBox
