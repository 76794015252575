import { useQuery } from 'react-query'
import { useAxios } from 'packages/core'
import config from 'config'

export const APPLICATION_SETTINGS_KEY = 'APPLICATION_SETTINGS_KEY'
export const ALL_APPLICATIONS_KEY = 'ALL_APPLICATIONS_KEY'
export const ENVIRONMENT_NAME_KEY = 'ENVIRONMENT_NAME_KEY'

export const useApplicationSettings = (settingsOverride) => {
    const callApi = useAxios()
    return useQuery(
        APPLICATION_SETTINGS_KEY,
        () =>
            callApi({
                method: 'GET',
                url: settingsOverride
                    ? `/api/configuration/settings?settingsOverride=${settingsOverride}`
                    : '/api/configuration/settings',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

// export const useAllApplications = () => {
//     const callApi = useAxios()
//     return useQuery(
//         ALL_APPLICATIONS_KEY,
//         () =>
//             callApi({
//                 method: 'GET',
//                 url: '/api/application/access/microservices',
//             }).then((data) => data.data),
//         {
//             staleTime: Infinity,
//         },
//     )
// }

export const useEnvironment = () => {
    const callApi = useAxios()
    return useQuery(
        ENVIRONMENT_NAME_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/configuration/environment',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}
export const useCheckIfMasterPasswordExist = () => {
    const masterPasswordExists = () => {
        const masterPassword = sessionStorage.getItem('MasterPassword')
        return masterPassword && masterPassword.length
    }
    return { masterPasswordExists }
}
